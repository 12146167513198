import React from 'react';

import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';

import './styles/_app.scss';

function App() {
  return (
    <div>

        {/* <Navbar bg="light"  expand="md" className="fixed-top">
            <Container>
                <Navbar.Brand>
                    <h5 className="mb-0">Castlefield Associates Inc.</h5>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav.Link  className="ml-auto" href="mailto:info@castlefieldassociates.com">info@castlefieldassociates.com</Nav.Link>
                </Navbar.Collapse>
            </Container>
        </Navbar> */}
        <div className="background vh-100 bg-primary d-flex flex-column justify-content-between">
            <Container>
                <Row className="h-100">
                    <Col>
                        <div className="splash">
                            <h1>Castlefield Associates </h1>
                            <div className="indent">
                                <h2>Systematically different.</h2>
                                <h5><a href="mailto:info@castlefieldassociates.com">info@castlefieldassociates.com</a></h5>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='footer bg-light text-center p-1'>
                <div>© Castlefield Associates Inc.</div>
                <div><small>Photo by <a href="https://unsplash.com/@larsstuifbergen">Lars Stuifbergen</a> on <a href="https://unsplash.com/">Unsplash</a></small></div>
            </Container>
        </div>
    </div>


  );
}

export default App;
